@import url("https://fonts.googleapis.com/css2?family=Satisfy&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;700&display=swap");

$primaryColor: #00b2ca;
$secondaryColor: #0e90a2;
$white: #f4f4f4;
$mobileWidth: 37vh;
$mobileHeight: 80vh;
$black: #2c2929;
$lightGray: #898989;

* {
  font-family: "Playfair Display", serif;
}

html, body {
  overflow-x: hidden !important;
  max-width: 100vw !important;
}

#hero {
  @media screen and (min-width: 992px) {
    height: 100vh;
  }
  
  @media screen and (max-width: 991px) {
    text-align: center;
    padding-top: 10vh;
    padding-bottom: 10vh;

    .col-lg-6:nth-of-type(1) {
      div {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }

    .col-lg-6:nth-of-type(2) {
      margin-top: 10vh;
    }

    h1 {
      font-size: 20px;
    }
  }

  background-image: url('./assets/bg-hero.jpg');
  background-size: contain;
  background-repeat: no-repeat;

  .row {
    height: 100%;
  }

  .col-lg-6 {
    height: 100%;
  }

  .col-lg-6:nth-of-type(1) {
    h1 {
      font-size: 70px;

      span {
        font-family: "Satisfy", cursive;
        background-color: #00b2ca;
        border-radius: 20px;
        color: $white;
        padding: 5px 30px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      }
    }

    p {
      margin-top: 40px;
      font-size: 30px;
    }
  }

  .col-lg-6:nth-of-type(2) {
    .video {
      border-radius: 20px;
      display: flex;
      overflow: hidden;
      position: relative;
      background-color: #fff;
      justify-content: center;
      align-items: center;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      height: $mobileHeight;
      width: $mobileWidth;

      video {
        display: block;
        width: $mobileHeight;
        height: 100%;
      }
    }
  }

  .blurred-card-1, .blurred-card-2, .blurred-card-3 {
    backdrop-filter: blur(100px);
    position: absolute;
    width: 250px;
    border-radius: 10px;
    padding: 10px 10px 12px 10px;
    color: white;
    background-color: #1d4e895e;
  }

  .get-google-play {
    height: 50px;
    cursor: pointer;
  }

  .get-apple-store {
    height: 51px;
    cursor: pointer;
  }
}

#camera, #summary, #ai {
  position: relative;

  @media screen and (min-width: 992px) {
    height: 100vh;
  }
  
  @media screen and (max-width: 991px) {
    text-align: center;
    padding-top: 10vh;
    padding-bottom: 10vh;

    .col-lg-6:nth-of-type(2) {
      margin-top: 10vh;
    }
  }

  .row,
  .col-lg-6 {
    height: 100%;
  }

  h2 {
    font-size: 70px;
    font-weight: bold;

    @media screen and (max-width: 767px) {
      font-size: 45px;  
    }
  }

  p {
    font-size: 22px;
  }

  span {
    font-size: 200px;
  }

  .col-lg-6:nth-of-type(2) {
    img {
      border-radius: 20px;
      display: flex;
      overflow: hidden;
      position: relative;
      background-color: #fff;
      justify-content: center;
      align-items: center;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      height: $mobileHeight;
      width: $mobileWidth;
      object-fit: cover;
    }
  }

  .camera-bg {
    position: absolute;
    width: 800px;
    z-index: -1;
    opacity: 0.3;
    transform: translate(-35vw, 0%);
  }

  .glasses-bg {
    position: absolute;
    width: 800px;
    z-index: -1;
    opacity: 0.15;
    transform: translate(-15vw, 70%);
  }

  .chat-bg {
    position: absolute;
    width: 500px;
    z-index: -1;
    opacity: 0.15;
    transform: translate(-1vw, 40%) rotate(-10deg);
  }
}

#help {
  h2 {
    font-size: 80px;
  }

  p {
    font-size: 20px;
    color: $black;
  }

  textarea {
    resize: none;
  }
}

.ResetPassword, .Validate {
  height: 100vh;

  .row {
    height: 100%;
    border-radius: 20px;
  }

  .col-6 {
    height: 100%;
  }

  .reset-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 400px;
    margin: 0 auto;
  }

  .input-apple-style {
    width: 100%;
    padding: 12px;
    border: 1px solid #d1d1d6;
    border-radius: 8px;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    background-color: #f5f5f7;
  }

  .apple-button {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    color: white;
    background-color: #00b2ca;
    border: none;
    border-radius: 8px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    cursor: pointer;
  }

  .apple-button:hover {
    background-color: #007d8e;
  }

  .col-6:nth-of-type(2) {
    background-color: #00b2ca;
    
    h1 {
      font-family: "Satisfy", cursive;
      color: white;
      margin-top: 25px;
      font-size: 90px;
    }

    div {
      height: 100%;
    }
  }

  .success {
    background-color: rgb(82, 200, 82);
    width: 200px;
    height: 200px;
    border-radius: 100px;

    .check-lg {
      font-size: 100px;
      color: white;
      transform: translate(-5px, 2px);
    }
  }

  .fail {
    background-color: rgb(255, 78, 78);
    width: 50px;
    height: 50px;
    border-radius: 100px;

    .x-lg {
      font-size: 25px;
      color: white;
    }
  }

  .logo {
    font-family: "Satisfy", cursive;
    color: $primaryColor;
    margin-top: 15px;
    margin-left: 20px;
    position: absolute;
    font-size: 45px;
  }

  h2 {
    font-size: 60px;
  }
}

#awards {
  margin-top: 10vh;
  margin-bottom: 10vh;

  h2 {
    font-size: 60px;
  }

  img {
    width: 130px;
  }

  p {
    font-size: 20px;
  }

  .link {
    cursor: pointer;
  }
}

footer * {
  font-family: "Plus Jakarta Sans", sans-serif !important;
}

footer {
  background-color: $black;
  color: $white;
  font-family: "Plus Jakarta Sans", sans-serif !important;

  h2 {
    font-size: 18px;
    text-transform: uppercase;
    color: $white;
    font-weight: 800;
  }

  a {
    color: $white !important;

    &:hover {
      color: $white !important;
    }
  }

  .social-links {
    font-size: 24px;

    * {
      margin-right: 25px;
      transition: 0.1s;
    }

    *:hover {
      color: $lightGray;
      transition: 0.1s;
      cursor: pointer;
    }
  }

  .payment {
    background-color: $white;
    border-radius: 6px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 67px;
    height: 43px;

    img {
      width: 41px;
    }
  }

  .links {
    li {
      list-style-type: none;
      margin-left: -30px !important;
      margin-bottom: 10px;
    }

    a {
      text-decoration: none;
      color: $white;
      transition: ease-in-out 0.1s;
    }

    a:hover {
      color: $lightGray;
      transition: ease-in-out 0.1s;
    }
  }
}